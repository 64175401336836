import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Counter() {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const styles = {
    counterWrapper: {
      backgroundColor: 'rgb(240 240 240)',
      color: '#b05821',
      textAlign: 'center',
      padding: '50px 0',
      borderRadius: '12px',
    },
    counterDiv: {
      fontSize: '2em',
      marginBottom: '20px',
    },
    counterText: {
      fontSize: '1em',
    },
    counterDivSmall: {
      fontSize: '1.5em',
      marginBottom: '15px',
    },
  };

  useEffect(() => {
    if (isClient) {
      const animateCounters = () => {
        const counters = document.querySelectorAll('.count');
        counters.forEach((counter) => {
          const updateCount = () => {
            const target = +counter.getAttribute('data-count');
            const count = +counter.innerText;
            const speed = 8000; 
            const inc = target / speed;
            if (count < target) {
              counter.innerText = Math.ceil(count + inc);
              setTimeout(updateCount, 1);
            } else {
              counter.innerText = target;
            }
          };
          updateCount();
        });
      };
      animateCounters();
    }
  }, [isClient]);

  return (
    <div style={styles.counterWrapper}>
      <Container>
        <Row className="counter">
          <Col md={4} xs={12}>
            <div style={isClient && window.innerWidth < 768 ? styles.counterDivSmall : styles.counterDiv}>
              <span className="count" data-count="25">0</span>+
              <div style={styles.counterText}>speakers</div>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div style={isClient && window.innerWidth < 768 ? styles.counterDivSmall : styles.counterDiv}>
              <span className="count" data-count="200">0</span>+
              <div style={styles.counterText}>delegates</div>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div style={isClient && window.innerWidth < 768 ? styles.counterDivSmall : styles.counterDiv}>
              <span className="count" data-count="8">0</span>
              <div style={styles.counterText}>hours event time</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
