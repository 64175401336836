import React from "react"
import Table from "react-bootstrap/Table"
import { Link } from "gatsby"
import WebsiteBannerAurovilleEvent from "../images/newDesign/banners/WebsiteBannerAurovilleEvent.jpg"
import Carousel from "react-multi-carousel"
import BannerData from "../assets/data/banners.json"
import auroville from "../images/newDesign/auroville/AurovilleEventWebsitephotos.jpg"
import whoShouldJoin from "../images/newDesign/WhoShouldJoin.png"
import coverphoto from "../images/newDesign/CoverPhoto.jpg"
import innaugration from "../images/newDesign/Innauguration.jpg"
import pannel1 from "../images/newDesign/Panel1.jpg"
import pannel2 from "../images/newDesign/Panel2.jpg"
import cscConclave from "../images/newDesign/cscConclave.jpeg"
import eventLocation from "../images/newDesign/auroville/even.jpg"
import Brochure from "../pdfs/CSCIESGConclaveBrochure.pdf"
import { KeyTakeaways, speakers,newsArticles, sponsors, eventVideo, glimpse } from "../data/events"
import { FaCheck } from "react-icons/fa";
import partners from "../images/newDesign/partners.jpeg"
import Counter from "./counter"
import { FaCalendarAlt } from 'react-icons/fa'; 
import vikrant from "../images/newDesign/Vikrant1.jpg"
import puneeta from "../images/newDesign/Puneeta1.jpg"
import sanjay from "../images/newDesign/Sanjay1.jpg"
export default function AuroVilleProgram() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  return (
    <>
      <div className="container-custom">
        <Carousel
          className="carousal-custom"
          responsive={responsive}
          itemClass="carousel-item-padding-40-px"
          draggable={false}
          showDots={true}
          infinite={true}
          autoPlay={true}
          arrows={true}
          autoPlaySpeed={3000}
        >
          {BannerData.map(data => {
            if (data.attributes.banner_type === "only_delhi_event_banner") {
              return (
                <div>
                  <div className="banner-btn-parent">
                    <Link className="nav-link" to={data.attributes.button_url}>
                      {data.attributes.button_lable && (
                        <button className="btn banner-btn -green mt-4">
                          {data.attributes.button_lable}
                        </button>
                      )}
                    </Link>
                  </div>
                  <div>
                    <img src={data.attributes.banner.data[0].attributes.url} />
                  </div>
                </div>
              )
            }
          })}
        </Carousel>

        <div className="mt-5 pt-0 mb-5 px-3 px-md-5 px-lg-5 px-xl-5">
          {/* <a
            href={Brochure}
            target="_blank"
            className="btn learn-more-btn  my-4"
          >
            Click to view the Brochure
          </a> */}
          {/* <Link
            to="/event-form"
            target="_blank"
            className="btn learn-more-btn  my-4 mx-3"
          >
            Click for Registration
          </Link> */}
         
      

          <h1 className="heading my-5">About CSC-IESGN SDG Conclave</h1>
          {/* <h3>Unveiling a Vision of Sustainable Transformation</h3> */}
          {/* <div className="comman-para">
            CSC empowers rural communities for poverty alleviation, gender equality, rural development,
            and Decent Work and Economic Growth. Our conclave highlights CSC Journey to <b>Grassroot
            Sustainability</b>.<br/>
            This occasion marks a collaborative effort with the <b>Indian ESG Network (IESGN)</b>, a prominent
            contributor to India's ESG progress. The Indian ESG Network plays a crucial role in fostering ESG
            awareness, enhancing capacity, and cultivating a network of ESG professionals and experts,
            offering vital support to the Sustainability journey of India.
          </div> */}


<div class="row">
  <div class="col-md-5 col-12">
  <img src={coverphoto} className="CoverPhoto"/>
  </div>
  <div class="col-md-7 col-12 comman-para" align ="justify">
    <p>
      The  <b>CSC-IESGN SDG Conclave 2024,</b> held in New Delhi, was a landmark event dedicated to advancing the Sustainable Development Goals (SDGs) through collaborative efforts and innovative solutions. This annual conclave brought together a diverse group of stakeholders to discuss and strategize on effective ways to integrate SDG initiatives across various sectors with a special focus on rural empowerment and technological interventions. This year's conclave focused on grassroots sustainability efforts, educational innovations, digital healthcare solutions, and community empowerment through financial and legal literacy.
    </p>
  </div>
</div>
<br></br>

<div class="row mt-2">
  
  <div class="col-md-7 col-12 comman-para" align ="justify">
  <p>
  The event started with lamp lightening in presence of <b>Hon'ble Atul Kumar Tiwari</b>, Secretary, Ministry of Skill Development and Entrepreneurship,<b> Sanjay Rakesh</b> , Chairman and Secretary of CSC Academy<b> Vikrant Abrol</b>, Founder IESGN, and <b>Puneeta Puri</b>, Executive Director of IESGN.
  </p>
  </div>
  <div class="col-md-5 col-12">
  <img src={innaugration} className="Innaugration"/>
  </div>
</div>



{/* 
          <h1 className="heading my-5">
            Key Takeaways from the CSC-IESGN SDG Conclave:
          </h1> */}

          <h1 className="heading my-5">
          Conclave Highlights
          </h1>

{/* 
          <div className="row">
      {KeyTakeaways.map((data, index) => (
        <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4 mt-3">
          <div className="card h-100 custom-card border common-card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <img
                    src={data.image}
                    alt="Speaker"
                    className="img-fluid w-100 h-50"
                  />
                  <h4 className="common-second-heading mt-1">
                    {data.heading}
                  </h4>
                  <p className="pt-2" align="justify">
                    {data.para}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div> */}

<div className="row">
         
              <div className="col-12 col-md-3 col-lg-3   mb-4 border text-center ">
                <img src={vikrant} alt={``} className="img-fluid offer-card" />
              </div>
              <div className="col-12 col-md-9 col-md-9 p-5 mb-4 text-start common-card">
                <h4 className="common-second-heading fw-bold">Vikrant Abrol, IESGN Founder</h4>
                <div align="justify" className="comman-para">
                Vikrant Abrol, IESGN Founder, came forward and mentioned importance of a network that work with various corporate for achieving SDGs.Also, he urged everyone to bring ideas for betterment of society from boardroom to ground level which would make environment a better place to live in. He mentioned importance of ESG's social aspect as well as role of CSC in sustainability through various pilot projects.
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-9 col-md-9 p-5 mb-4 text-start common-card order-last order-sm-last order-md-first order-lg-first order-xxl-first">
                <h4 className="common-second-heading fw-bold">Puneeta Puri, Executive Director of IESGN </h4>
                <div align="justify" className="comman-para">
                Explained social aspect of SDGs. She also differentiated ESG from SDG, where ESG is a wider perspective and it is now adopted by corporate in corporate social responsibility (CSR). She also appreciated role of CSC and its projects that are fulfilling motives of different SDG and ESG goals.
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 justify-content-center mb-4 border text-center order-first order-sm-first order-md-last order-lg-last order-xxl-last">
                <img src={puneeta} alt={``} className="img-fluid offer-card" />
              </div>
            </div>
            <div className="row">
            
              <div className="col-12 col-md-3 col-lg-3  mb-4 border text-center ">
                <img src={sanjay} alt={``} className="img-fluid offer-card" />
              </div>
              <div className="col-12 col-md-9 col-md-9 p-5 mb-4 text-start common-card">
                <h4 className="common-second-heading fw-bold">Sanjay Rakesh, Chairman and Secretary of CSC Academy</h4>
                <div align="justify" className="comman-para">
                Emphasized the significant role of digital platforms in enhancing education and skilling at the rural level while giving the keynote address. He also mentioned the CSC's commitment towards sustainability through various initiatives such as Bal Vidyalaya, Digital Vans, Stree Swabhiman, and many more. He also emphasised on the development of Digital Library and empowering Anganwadi centres with technology that will help in reducing urban migration and empowering local communities.
                </div>
              </div>
            </div>


    <div className=" counter my-5">
      <Counter />
    </div>

    <h1 className="heading my-5">Speakers</h1>

    <div className="row">
      {speakers.map((data, index) => (
        <div key={index} className="col-12 col-md-4 col-lg-3 mt-3">
          <div className="card h-100 custom-card border common-card">
            <div className="card-body">
                  <img
                    src={data.image}
                    alt="Speaker"
                    className="img-fluid "
                  />
                  <h4 className="common-second-heading mt-1">
                    {data.heading}
                  </h4>
                  <p className="pt-2" >
                    {data.para}
                  </p>
            </div>
          </div>
        </div>
      ))}
    </div>


<div className="my-6">
    <div class="row mt-5 align-items-center">
  <div class="col-12 col-md-5">
  <img src={pannel1} className="Pannel1"/>
  </div>
  <div class="col-12 col-md-7 comman-para" >
    <h2 className="heading">Panel Discussion on High Impact Social Projects that Contributes to SDGs</h2>
    <p align ="justify"> 
    Moderated by Mr. Vignesh Sornamohan, SVP, CSC SPV,the discussion featured insights from various leaders on integrating SDG efforts in high impact projects. Several eminent personalities joined in the session that includes Dr. B. Chandrasekar, ED, EDCIL, Ms Girija Mukund, Director-Corporate Social Responsibility and ESG, Kyndryl, Mr Pratik Kumar, CEO, Sashakt Foundation, and Mr Praveen Dwivedi, Senior Vice President, Public Sector & Public Affairs, Wheebox ETS.
    </p>
  </div>
</div>
</div>
<br></br>

<div class="row align-items-center">
  <div class="col-md-7 col-12 comman-para">
  <h2 className="heading">Panel Discussion on ESG vs CSR</h2>
    <p align ="justify"> 
    Another relevant panel discussion was conducted ESG vs. CSR. This session debated the evolving landscape of corporate social responsibility, comparing environment, society, and governance (ESG) and traditional CSR approaches that are adopted by corporates. The session was moderated by Puneeta Puri, Executive Director, IESGN and various eminent members shared their views in discussion that includes Dinesh Aggarawal, Principle Advisor Consocia Advisory, Akshay Dhoot, Director, Smartping, Vidushi Saxena, Solution Consultant at Adobe, Pradeep Singh, Head Monitoring and Impact at HDFC Bank, and Sanjeev Sehgal, MD and Founder of Sparsh.
    </p>
  </div>
  <div class="col-md-5 col-12">
  <img src={pannel2} className="Panel2"/>
  </div>
</div>


          <h1 className="heading my-5">News and Articles</h1>

          <div className="row">
  {newsArticles.map((data, index) => (
    <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4 mt-3">
      <div className="card h-100 custom-card border common-card">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <FaCalendarAlt className="mr-2 " />
            <span className="ms-1">{data.dop}</span>
          </div>
          <h4 className="common-second-heading mt-1">{data.heading}</h4>
          <p className="pt-2">{data.para}</p>
          <p className="pt-2">
            <a href={data.link} style={{ textDecoration: 'underline' }}>
              Read more
            </a>
          </p>
        </div>
      </div>
    </div>
  ))}
</div>



<h1 className="heading my-5">Sponsors</h1>

<div className="row">
      {sponsors.map((data, index) => (
        <div key={index} className="col-12 col-md-4 col-lg-2 mt-3">
          <div className="card h-100 custom-card border common-card">
            <div className="card-body">
                  <img
                    src={data.image}
                    alt="sponser"
                    className="img-fluid "
                  />
                
            </div>
          </div>
        </div>
      ))}
    </div>

    <h1 className="heading my-5">Videos</h1>


    <div className="row">
      {eventVideo.map((data, index) => (
        <div key={index} className="col-12 col-md-6 col-lg-6 mt-3">
        <div className="col-md-12">
                        <iframe
                          width="98%"
                          height="315"
                          src={data.url}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>
        </div>
      ))}
    </div>

    <h1 className="heading my-5 ">Glimpses of the Conclave</h1>

    <div className="row">
      {glimpse.map((data, index) => (
        <div key={index} className="col-12 col-md-4 col-lg-4 mt-3">
          <div className="card h-100 custom-card border common-card">
            <div className="card-body">
                  <img
                    src={data.image}
                    alt="glimpse"
                    className="img-fluid "
                  />
                
            </div>
          </div>
        </div>
      ))}
    </div>

          {/* <div className="comman-para">
          <b>Two Panel Discussions</b> with experts covering key topics on E,S & G.
            <ul>
              <li>
                <b>Strategies for Large - Scale Social Impact:</b> Building on the Social Aspect of ESG
              </li>
              <li>
                <b>ESG vs. CSR:</b> Changing landscape of CSR
              </li>
            </ul>
            The conclave will showcase CSC's journey, key achievements, projects, progress in achieving
            <b> Sustainable Development Goals</b> via collaborative programs with Key Partners, Social Change
            Agents, and CSR Partners who significantly contributed in the Sustainability Journey.
          </div>

          <h1 className="heading my-5">WHO SHOULD JOIN?</h1>
          <img src={whoShouldJoin} className="img-fluid"/> */}

          {/* <div className="comman-para">
            This event is tailor-made for anyone committed to the cause of
            sustainability - <b>corporates, consulting firms, non-profit organizations,
            start-ups focused on sustainability, academicians, students, and
            individuals </b>passionate about making a difference from the ground up.
          </div> */}


          {/* <div className="comman-para my-5">
            <div className="row"> */}
              {/* <div className="col-md-6">
                <img src="" className="img-fluid"/>
              </div> */}
              {/* <div className="col-md-12">
                <h1 className="heading my-2">Exhibitor Categories</h1>
                The exhibition space will welcome participants to explore pioneering solutions in sustainability - Sustainable Solution Providers, Sustainability Consulting, Audit & Assurance Services, Sustainable Products.
                {/* <br/> <br/> *
                For more info visit:<br/>
                www.indianesgnetwork.com<br/>
                or www.csc.gov.in */}
              {/* </div>
            </div>
          </div>  
          <div className="comman-para my-5">
            <div className="row"> */}
              {/* <div className="col-md-6">
                <img src="" className="img-fluid"/>
              </div> */}
              {/* <div className="col-md-12">
                <h1 className="heading">LET US BUILD A SUSTAINABLE FUTURE</h1>
                In the heart of India's drive towards a sustainable future, lies the conviction that real change begins at the grassroots level. With this vision, CSC, in collaboration with the Indian ESG Network (IESGN), is thrilled to present the SDG conclave highlighting “CSC's Journey to Grassroots Sustainability" "सी एससी का आधार - ग्रामीण विकास सतत विकास" <br/><br/>

                Let's come together on (3rd May, 2024), to celebrate, learn, and commit to a
                sustainable future. For participation, sponsorship opportunities, or more
                information, please feel free to get in touch with us at 
                <b> contact@indianesgnetwork.com</b>
              </div>
            </div>
          </div>     */}


           {/* <div className="comman-para my-5">
            <div className="row"> */}
              {/* <div className="col-md-6">
                <img src="" className="img-fluid"/>
              </div> */}
              {/* <div className="col-md-12">
                <h1 className="heading my-5">CARBON NEUTRAL CONCLAVE</h1>
                The CSC- IESGN Team commits to making the conclave carbon neutral by utilizing recycled
                products, avoiding single-use plastics, and planting 1000 trees in rural schools across India.
                {/* <img className="img-fluid" src={cscConclave}/> 
              </div>
            </div>
          </div>      

          <h1 className="heading my-5"> PLANS FOR SPONSORSHIP & PARTNERSHIPS</h1> */}


          {/* <Table
            responsive
            striped
            bordered
            style={{ fontSize: "19px", textAlign: "center" }}
            className="mb-4"
          >
            <thead>
              <tr>
                <th colSpan={4}>
                Sponsorship/ Partnership and Co-Hosting Opportunities
                </th>
              </tr>
              <tr>
              <th className="heading background-price-td"></th>
                <th className="heading background-price-td">Category 1<br/>(Strategic Partner, Principal Partner, Corporate Partner, State Host)</th>
                <th className="heading background-price-td">Category 2 <br/> (Sectoral Corporate Partner, Sectoral Skilling Partner, Large Associations)</th>
                <th className="heading background-price-td">Category 3 <br/> (NGO Partner, MSME Partner, Academic Partner, Small Associations)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>INR*</td>
                <td>
                10,00,000
                </td>
                <td>
                5,00,000
                </td>
                <td>
                2,00,000
                </td>
              </tr>
              <tr>
                <td>Logo in Conclave Brochure</td>
                <td><FaCheck /></td>
                <td><FaCheck /></td>
                <td><FaCheck /></td>
              </tr>
              <tr>
                <td>Backdrop/Side Backdrop</td>
                <td><FaCheck /> Ultra-Premium</td>
                <td><FaCheck /> Premium</td>
                <td><FaCheck /></td>
              </tr>
              <tr>
                <td>Website Logo Placement</td>
                <td><FaCheck /> Ultra-Premium</td>
                <td><FaCheck /> Premium</td>
                <td><FaCheck /></td>
              </tr>
              <tr>
                <td>Speaking Opportunity</td>
                <td>Inaugural Keynote + Panel</td>
                <td>Panel</td>
                <td>Panel</td>
              </tr>
              <tr>
                <td>Social Media Promotion</td>
                <td><FaCheck /> Ultra-Premium</td>
                <td><FaCheck /> Premium</td>
                <td><FaCheck /></td>
              </tr>
              <tr>
                <td>Display Promotion 6*3ft</td>
                <td>3 standees</td>
                <td>2 standee</td>
                <td>1 standee</td>
              </tr>
              <tr>
                <td>Premium Delegate Pass (including Networking Lunch)</td>
                <td>7</td>
                <td>5</td>
                <td>2</td>
              </tr>
              <tr>
                <td>Name/Logo on Lanyard</td>
                <td><FaCheck /></td>
                <td><FaCheck /></td>
                <td>-</td>
              </tr>
              <tr>
                <td>Logo on the Agenda</td>
                <td><FaCheck /></td>
                <td><FaCheck /></td>
                <td>-</td>
              </tr>
              <tr>
                <td>Lunch with VVIPs/ ESG Heads/ Government Officials</td>
                <td><FaCheck /></td>
                <td><FaCheck /></td>
                <td><FaCheck /></td>
              </tr>
            </tbody>
          </Table>
          Taxes as applicable (GST @ 18%) shall be charged extra on the quoted rates
        <div className="text-center mt-5">
          <img className="img-fluid" src={partners} />
        </div> */}
        </div>
      </div>
    </>
  )
}
